<template>
    <div>
        <myhead :param="param" />
        <section>
            <div class="bannersys">
                  <img :src="bannername">
            </div>
        </section>
        <section style="background-color: #f3f3f3">
            <div class="htable" style="height: 64px;justify-content:center;align-items:center;font-size: 16px;">
                <div class="hcell" style="padding-right:42px;border-right: 1px solid rgba(20,20,20,0.7);font-weight: bold;">智慧路灯</div>
                <div class="hcell" style="margin-left: 42px;"><span class="mgd" @click="go('hic')">HIC智慧照明</span></div>
                <div class="hcell" style="margin-left: 80px;"><span class="mgd" @click="go('hsc')">HSC智慧市政</span></div>
                <div class="hcell" style="margin-left: 80px;"><span class="mgd" @click="go('hpc')">HPC智慧园区</span></div>
                <div class="hcell" style="margin-left: 80px;"><span class="mgd" @click="go('hoc')">HOC智慧办公</span></div>
            </div>
        </section>
        <router-view ></router-view>
        <section style="height: 90px;"></section>
    </div>
</template>
<script>
    import myhead  from  '../components/myhead.vue'
    const param = {
        pageindex: 2,
        rows: 6,
        page: 1,
    }
    export default {
        components: {
            myhead,

        },
        data() {
            return {
                param,
                bannername: require('../assets/hoc_banner.jpg'),
            }
        },
        mounted() {

        },
        methods: {
            go(url) {
                if (url=="hoc")  this.bannername= require('../assets/hoc_banner.jpg')
                if (url=="hic")  this.bannername= require('../assets/hic_banner.jpg')
                if (url=="hsc")  this.bannername= require('../assets/hsc_banner.jpg')
                if (url=="hpc")  this.bannername= require('../assets/hpc_banner.jpg')
                this.$router.push({ path: "/system/"+url, query:null });
            },
            hocbanner(){
                this.bannername= require('../assets/hoc_banner.jpg')
            },
            hicbanner(){
                this.bannername= require('../assets/hic_banner.jpg')
            },
            hscbanner(){
                this.bannername= require('../assets/hsc_banner.jpg')
            },
            hpcbanner(){
                this.bannername= require('../assets/hpc_banner.jpg')
            },
        },
    }
</script>
<style scoped>
    .bannersys{
        height: 600px;
    }
    .mgd {
        cursor:pointer;
        padding: 0 5px;
    }
    .mgd:hover {
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        /*border-radius: 5px;*/
    }
</style>
